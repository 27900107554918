import React from 'react';
import {
  Pagination,
  StyledArrowLeftIcon,
  StyledArrowRightIcon,
} from './PaginationArrowsSlider.stye';
import { PropsWithChildren } from 'react';

interface IProps {
  index: number;
  totalItems: number;
  position?: string;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
  currentResponsive: number;
  infinite?: boolean;
}

const PaginationArrowsSlider: React.FunctionComponent<IProps> = (
  props: PropsWithChildren<IProps>
) => {
  const { children } = props;

  const handleLeftClick = () => {
    props?.onLeftArrowClick();
  };

  const handleRightClick = () => {
    props?.onRightArrowClick();
  };

  return (
    <Pagination>
      {((!props?.infinite && props?.index !== 0) || props?.infinite) && (
        <StyledArrowLeftIcon
          disabled={props?.index === 0}
          onClick={
            props?.index === 0
              ? props?.infinite
                ? handleLeftClick
                : () => {}
              : handleLeftClick
          }
        />
      )}
      {((!props?.infinite &&
        props?.index + props?.currentResponsive !== props?.totalItems) ||
        props?.infinite) && (
        <StyledArrowRightIcon
          disabled={props?.index === props?.totalItems - 1}
          onClick={
            props?.index === props?.totalItems - 1
              ? props?.infinite
                ? handleRightClick
                : () => {}
              : handleRightClick
          }
        />
      )}
      {children}
    </Pagination>
  );
};

export default PaginationArrowsSlider;
