import React, { useEffect, useState, useRef, useMemo } from 'react';
import BaseCarousel from '../base-carousel/BaseCarousel';
import {
  Carousel,
  CarouselWrapper,
  Container,
} from './CarouselGridSingleRow.style';
import Card from '../../cards/card/Card';
import { Responsive } from 'react-alice-carousel';
import { useWindowSize } from 'usehooks-ts';
import CardGrid from '../../cards/card-grid/CardGrid';
import PaginationArrowsSlider from '../../pagination-arrows-slider/PaginationArrowsSlider';

interface Card {
  title: string;
  link: string;
  image: any;
  overlayColor: string;
}

interface GridCarouselProps {
  cards: Card[];
  itemPerPage?: Responsive;
  isPrerenderRequest: boolean;
}

const GridCarouselSingleRow: React.FC<GridCarouselProps> = (
  props: GridCarouselProps
) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef();
  const slidePrev = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex - 1);
  };
  const slideNext = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex + 1);
  };
  const syncActiveIndex = ({ item }: any) => setActiveIndex(item);
  const [currentResponsive, setCurrentResponsive] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    setActiveIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const cardsMapper = (cards: any[]) =>
    cards?.map((card, i) => ({
      id: i,
      image: card.image,
      category: card.category,
      title: card.title,
      description: card.description,
      button: {
        ...card.button,
        link: card?.button?.url,
      },
      overlayColor: card.overlayColor,
      overlayColorHover: card.overlayColorHover,
      link: card.link,
    }));

  const carouselItems = React.Children.toArray(
    cardsMapper(props.cards)?.map((items) => (
      <CardGrid data={items} isPrerenderRequest={props.isPrerenderRequest} />
    ))
  );

  const responsive = useMemo(() => {
    return {
      0: { items: 1 },
      568: { items: 3 },
      1024: { items: 4 },
    };
  }, []);

  useEffect(() => {
    const result = Object.keys(responsive)
      ?.slice()
      ?.reverse()
      ?.find?.((key) => {
        return width > ~~key;
      });
    const currentResponsive: any = (responsive as any)[result || '0'];
    setCurrentResponsive(currentResponsive?.items || 1);
  }, [responsive, width]);

  return (
    <Carousel>
      <Container>
        <PaginationArrowsSlider
          index={activeIndex}
          totalItems={carouselItems.length}
          currentResponsive={currentResponsive}
          onLeftArrowClick={slidePrev}
          onRightArrowClick={slideNext}
          infinite={false}
        >
          <CarouselWrapper>
            <BaseCarousel
              {...props}
              responsive={responsive}
              mouseTracking={true}
              disableButtonsControls
              disableDotsControls
              activeIndex={activeIndex}
              onSlideChanged={syncActiveIndex}
              items={carouselItems}
              dotsAlignment={'center'}
              refFromParentCb={(refFromChild: any) => {
                ref.current = refFromChild;
              }}
              infinite={false}
            />
          </CarouselWrapper>
        </PaginationArrowsSlider>
      </Container>
    </Carousel>
  );
};

export default GridCarouselSingleRow;
