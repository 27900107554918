import React from 'react';
import { StyledContentWrapper } from './GridSingleRow.style';
import {} from 'packages/corporate-ui/src/styles/shared';
import GridCarouselSingleRow from '../../../components/carousel/carousel-grid-single-row/CarouselGridSingleRow';

interface GridProps {
  cards: any;
}

const Grid: React.FC<any> = ({
  data,
  isPrerenderRequest,
}: {
  data: GridProps;
  isPrerenderRequest: boolean;
}) => {
  return (
    <StyledContentWrapper>
      <GridCarouselSingleRow
        cards={data.cards}
        isPrerenderRequest={isPrerenderRequest}
      />
    </StyledContentWrapper>
  );
};

export default Grid;
