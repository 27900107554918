import { styled } from '@mui/material';
import {
  ArrowBackIosOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from '../../assets';

export const Pagination = styled('div')(() => ({
  position: 'relative',
}));

export const Page = styled('span')(() => ({
  fontWeight: 'bold',
  margin: 0,
}));

export const StyledArrowLeftIcon = styled((props: any) => (
  <ArrowBackIosOutlinedIcon {...props} />
))(({ theme, disabled }) => ({
  zIndex: '2',
  width: theme.spacings.unit(7.5),
  height: theme.spacings.unit(7.5),
  display: disabled ? 'none' : 'block',
  color: '#000000',
  '&:hover': {
    color: theme.colors.primary,
    cursor: 'pointer',
  },
  float: 'left',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  marginLeft: `-${theme.spacings.unit(10.5)}`,
}));

export const StyledArrowRightIcon = styled(ArrowForwardIosOutlinedIcon)(
  ({ theme, disabled }) => ({
    zIndex: '2',
    width: theme.spacings.unit(7.5),
    height: theme.spacings.unit(7.5),
    display: disabled ? 'none' : 'block',
    color: '#000000',
    '&:hover': {
      color: theme.colors.primary,
      cursor: 'pointer',
    },
    float: 'right',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    [theme.breakpointsMediaQuery.up('md')]: {
      marginRight: `-${theme.spacings.unit(6)}`,
    },
  })
);
