import { styled } from '@mui/material';

export const Carousel = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingLeft: theme.spacings.unit(4),
  },
}));

export const Container = styled('div')(({ theme }) => ({
  width: '95%',
  margin: '0 auto',
  [theme.breakpointsMediaQuery.up('md')]: {
    '& > div': {
      '& > svg': {
        marginRight: theme.spacings.unit(-35 / 4),
      },
      '& > svg + svg:not(:last-child)': {
        marginLeft: theme.spacings.unit(-35 / 4),
      },
      '& > div': {
        '& > div': {
          width: '99%',
        },
      },
    },
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    '& > div': {
      '& > svg:first-child': {
        marginLeft: 0,
      },
    },
  },
}));

export const CarouselWrapper = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.down('md')]: {
    marginLeft: theme.spacings.unit(9),
    marginRight: theme.spacings.unit(9),
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    position: 'relative',
    '& ul li': {
      paddingRight: '16px',
      paddingLeft: '1px',
    },
  },
}));
