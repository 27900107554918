import { styled } from '@mui/material';

export const StyledContentWrapper = styled('div')(({ theme }) => ({
  maxWidth: `${theme.spacings.unit(490)}`,
  [theme.breakpointsMediaQuery.down('md')]: {
    paddingTop: theme.spacings.unit(0),
    marginBottom: theme.spacings.unit(14),
    paddingRight: `calc(${theme.spacings.unit(20 / 4)} + ${theme.spacings.unit(
      10
    )})`,
    paddingLeft: `calc(${theme.spacings.unit(20 / 4)} + ${theme.spacings.unit(
      10
    )})`,
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    paddingTop: theme.spacings.unit(0),
    marginBottom: theme.spacings.unit(14),
    paddingRight: theme.spacings.unit(20 / 4),
    paddingLeft: theme.spacings.unit(20 / 4),
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(12)} ${theme.spacings.unit(
      26
    )} ${theme.spacings.unit(24)} ${theme.spacings.unit(26)}`,
    margin: '0 auto',
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    paddingLeft: theme.spacings.unit(50 / 4),
    paddingRight: theme.spacings.unit(50 / 4),
  },
}));

export const MainRepeater = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexAlign: 'center',
  justifyContent: 'center',
}));
